import { request, serverNameJava, serverNamePhp } from '../configs.js';

export const assessoresRequests = {
  getAll: () => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.get(`${serverNameJava}/advisors?v=${Math.random()}`, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'application/json',
      },
      cache: 'no-store'
    });
  },
  getById: (id) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.get(`${serverNameJava}/advisors/${id}?v=${Math.random()}`, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'multipart/form-data',
      },
      cache: 'no-store',
    });
  },
  create: (formData) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.post(`${serverNameJava}/advisors`, formData, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  edit: (id, formData) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.put(`${serverNameJava}/advisors/${id}`, formData, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  checkIfImageExists: async (imgUrl) => {
    try {
      const response = await fetch(`${serverNamePhp}/uploads/${imgUrl}?v=${Math.random()}`, { method: 'HEAD' });
      return response;
    } catch (error) {
      console.error('Erro ao verificar a imagem:', error);
      return { ok: false };
    }
  },
};

export default assessoresRequests;
