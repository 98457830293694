import React from 'react';
import { Form, Input } from 'antd';
import './FormulaDrawingForm.scss';

const FormulaDrawingForm = () => {

  return <div className="FormulaDrawingForm">
    <Form.Item
      name="formula_vencedor_texto"
      label="Texto vencedor"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="formula_sorteio_data"
      label="Sorteio e data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="formula_vencedor_nome"
      label="Nome do vencedor"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>
  </div>
};
export default FormulaDrawingForm;