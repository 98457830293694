import React from 'react';
import { Button, Upload } from 'antd';
import { InfoCircleOutlined, UploadOutlined, RollbackOutlined } from '@ant-design/icons';
import './UploadImage.scss';

const UploadImage = ({ fileList, setFileList, setImg, img, savedFileList, accept, name }) => (
  <div className="UploadImage">
    <h4>{name}</h4>
    <div className="upload-container">
      <Upload
        className="upload-component"
        onRemove={(file) => {
          setImg(null);
        }}
        onChange={(value) => {
          setFileList(value.fileList);
          if (value.fileList.length > 0) {
            setImg({ ...img, img: value.fileList[0].originFileObj });
          } else {
            setImg({ ...img, img: null });
          }
        }}
        beforeUpload={(file) => {
          return false;
        }}
        fileList={fileList}
        listType="picture"
        maxCount={1}
        accept={accept}
        onPreview={()=>null}
      >
        <Button icon={<UploadOutlined />}>Selecionar imagem {name}</Button>
      </Upload>
      {savedFileList.length > 0 && fileList.length == 0 && (
        <div className="undo-delete">
          <Button icon={<RollbackOutlined />} onClick={() => setFileList(savedFileList)} danger>Desfazer exclusão</Button>
          <div className="info-container">
            <InfoCircleOutlined />
            Imagem excluída!
            <br />
            Caso nenhuma imagem seja adicionada, será gerado sem imagem ou não será gerado quando a imagem for obrigatória
          </div>
        </div>
      )}
    </div>
  </div>
);


export default UploadImage;
