import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form, message, Spin } from 'antd';
import { pagesList } from '../../constants/constants.js';
import { initialValues } from './FormInitialValues.js';
import ImagePreview from '../imagePreview/ImagePreview.js';
import ModalSaveDraftForm from '../modalSaveDraftForm/ModalSaveDraftForm.js';
import FormDraftList from '../formDraftList/FormDraftList.js';
import ListFiles from '../listFiles/ListFiles.js';
import { packageGeneratorRequests } from '../../requests';
import { FORMULARIO_ARQUIVOS, FORMULARIO_REPETIDORES, FORMULARIO_SELETORES_COR } from '../../constants/constants';

import './PackageGenerator.scss';

const PackageGenerator = ({ type }) => {
  const [form] = Form.useForm();
  const [currentForm, setCurrentForm] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [version, setVersion] = useState(Math.random());
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [draftVersion, setDraftVersion] = useState(Math.random());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const item = Object.values(pagesList).find((v) => v.slug === type);
    setCurrentForm(item ? item.component : null);
  }, [type, form]);

  const validateForm = (callback) => {
    form
      .validateFields()
      .then((values) => {
        createFormData(values, callback);
      })
      .catch((errorInfo) => {
        console.log('Erro na validação:', errorInfo);
      });
  }


  const createFormData = async (values, callback) => {
    try {
      setLoading(true);

      const keys = Object.keys(values);
      const formData = new FormData();
      keys.forEach((key) => {
        if (FORMULARIO_ARQUIVOS.indexOf(key) !== -1) { // Arquivos
          if (values[key] && values[key][0]) formData.append(key, values[key][0].originFileObj);
        } else if (FORMULARIO_REPETIDORES.indexOf(key) !== -1) { // Repetidores
          formData.append(key, JSON.stringify(values[key]));
        } else if (FORMULARIO_SELETORES_COR.indexOf(key) !== -1) { // Cores
          formData.append(key, JSON.stringify(values[key].metaColor ? values[key].toRgb() : values[key]));
        } else  {
          if (values[key] !== undefined) {
            formData.append(key, values[key]);
          }
        }
      });

      await callback(formData);

      setVersion(version + 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
  };

  const generateImages = async (formData) => {
    const response = await packageGeneratorRequests.generatePackage(type, formData);
  }

  return (
    <div className="PackageGenerator">
      {contextHolder}
      <Spin tip="Carregando" spinning={loading}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={initialValues}
          autoComplete="off"
        >
          {currentForm}

          <div className="form-actions">
            <Form.Item>
              <Button onClick={() => setIsDraftModalOpen(true)}>
                Salvar rascunho
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" onClick={() => validateForm(generateImages)}>
                Gerar pacote
              </Button>
            </Form.Item>
          </div>

        </Form>

        <Collapse
          items={[
             {
               key: '1',
               label: 'Restaurar rascunhos',
               children: <FormDraftList type={type} form={form} version={draftVersion} />,
             },
             {
               key: '2',
               label: 'Pré-visualizar imagens',
               children: <ImagePreview type={type} validateForm={validateForm} />,
             },
          ]}
          defaultActiveKey={[]}
        />


        <ListFiles type={type} version={version} setLoading={setLoading}/>
      </Spin>
      <ModalSaveDraftForm
        type={type}
        form={form}
        isOpen={isDraftModalOpen}
        setIsOpen={setIsDraftModalOpen}
        setVersion={setDraftVersion}
      />
    </div>
  );
};
export default PackageGenerator;