import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message, Select, Spin } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { assessoresRequests } from '../../requests';
import './GerenciarAssessores.scss';
import { ESCRITORIOS } from '../../constants/constants';
import { generateSlug } from '../../utils/strings';
import UploadImage from './components/uploadImage/UploadImage';
import UploadRoundImage from './components/uploadRoundImage/UploadRoundImage';

const { Option } = Select;

const base64ToBlob = (base64, mimeType = 'image/png') => {
  const byteString = atob(base64.split(',')[1]); // Decodifica a parte base64
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: mimeType });
};

const base64ToFile = (base64, fileName = 'image.png', mimeType = 'image/png') => {
  const blob = base64ToBlob(base64, mimeType);
  return new File([blob], fileName, { type: mimeType });
};

function GerenciarAssessores() {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    active: true,
    company: 'nenhum',
    username: '',
    name: '',
    nameBulletin: '',
    slug: '',
  });

  const [profileImg, setProfileImg] = useState(null);
  const [profileFileList, setProfileFileList] = useState([]);
  const [savedProfileFileList, setSavedProfileFileList] = useState([]);

  const [pdfImg, setPdfImg] = useState(null);
  const [pdfFileList, setPdfFileList] = useState([]);
  const [savedPdfFileList, setSavedPdfFileList] = useState([]);

  const [qrCodeImg, setQrCodeImg] = useState(null);
  const [qrCodeFileList, setQrCodeFileList] = useState([]);
  const [savedQrCodeFileList, setSavedQrCodeFileList] = useState([]);

  const getCurrentImg = async (setFileList, setSavedFileList, imageUrl) => {
    if (id) {
      const response = await assessoresRequests.checkIfImageExists(imageUrl);
      if (response.ok) {
        const list = [{
          uid: '-1',
          status: 'done',
          url: `${response.url}?v=${Math.random()}`,
        }];
        setFileList(list);
        setSavedFileList(list);
        return;
      }
    }
    setFileList([]);
  };

  useEffect(() => {
    getCurrentImg(setProfileFileList, setSavedProfileFileList, `perfil/${id}.png`);
    getCurrentImg(setPdfFileList, setSavedPdfFileList, `jornal/${id}.png`);
    getCurrentImg(setQrCodeFileList, setSavedQrCodeFileList, `qrcode/${id}.jpg`);
  }, [id]);

  const onNameChange = (e) => {
    const name = e.target.value;
    const slug = generateSlug(name); // Remove spaces
    form.setFieldsValue({ slug });
  };

  const fetchData = useCallback(async () => {
    // Função para fazer a solicitação GET
    setLoading(true);
    try {
      const response = await assessoresRequests.getById(id);
      const { data } = response;
      if (!data.error) {
        setInitialValues({
          active: data.active,
          company: data.company || 'nenhum',
          username: data.username,
          name: data.name,
          nameBulletin: data.nameBulletin,
          slug: data.slug,
        });
      }
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
    setLoading(false);
  }, [messageApi, form, id]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (location.pathname.startsWith('/assessores/editar/')) {
      fetchData(); // Chama a função de busca quando o componente é montado
    }
  }, [fetchData, location.pathname]);

  const handleSubmit = async () => {


    form
      .validateFields()
      .then((values) => {

        setLoading(true);

        const formData = new FormData();
        const params = {
          active: values.active,
          company: values.company === 'nenhum' ? null : values.company,
          username: values.username,
          name: values.name,
          nameBulletin: values.nameBulletin,
          slug: values.slug,
        };

        // Dados do formulário como binário
        // Converte o objeto JSON em um `Blob` com o `Content-Type` como `application/json`
        const advisorBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
        formData.append('advisor', advisorBlob);

        formData.append('qrCodeImg', qrCodeImg?.img || null);

        if (profileFileList.length < 1) {
          formData.append('removeProfileImg', true);
        } else {
          if (profileFileList[0].uid === '-2') {
            formData.append('profileImg', base64ToFile(profileFileList[0].url));
          }
        }
        if (pdfFileList.length < 1) {
          formData.append('removePdfImg', true);
        } else {
          if (pdfFileList[0].uid === '-2') {
            formData.append('pdfImg', base64ToFile(pdfFileList[0].url));
          }
        }
        if (qrCodeFileList.length < 1) {
          formData.append('removeQrCodeImg', true);
        }

        setLoading(true);

        if (location.pathname.startsWith('/assessores/editar/')) {
          assessoresRequests.edit(id, formData).then((response) => {
            setLoading(false);
            if (!response.error) {
              message.success('Usuário atualizado com sucesso');
              navigate('/assessores/listar');
            } else {
              message.error('Ocorreu um erro ao atualizar o usuário');
            }
          }).catch((err) => {
            message.error('Ocorreu um erro, tente novamente mais tarde');
            setLoading(false);
          });
        } else {
          assessoresRequests.create(formData).then((response) => {
            setLoading(false);
            if (!response.error) {
              message.success('Usuário criado com sucesso');
              navigate('/assessores/listar');
            } else {
              message.error('Ocorreu um erro ao cadastrar o usuário');
            }
          }).catch((err) => {
            message.error('Ocorreu um erro, tente novamente mais tarde');
            setLoading(false);
          });
        }
      })
      .catch((errorInfo) => {
        setLoading(false);
        console.log('Erro na validação:', errorInfo);
      });
  };

  return (
    <div className="GerenciarAssessores">
      {contextHolder}
      <Spin tip="Carregando" spinning={loading}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={initialValues}
          autoComplete="off"
          onFinish={() => handleSubmit()}
        >

          <Form.Item
            label="Nome Stories"
            name="name"
            rules={[{ required: true, message: 'Please enter the advisor name!' }]}
          >
            <Input onChange={onNameChange} />
          </Form.Item>

          <Form.Item
            label="Slug (Nome no arquivo)"
            name="slug"
            rules={[{ required: true, message: 'Please enter a slug!' }]}
            help="Gerado automaticamente. Alterar apenas caso haja mais de um usuário com o mesmo nome gerado"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Username (@)"
            name="username"
            rules={[{ required: true, message: 'Please enter the username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nome jornal"
            name="nameBulletin"
            help="Caso use um nome diferente dos stories. Usado normalmente para assessores com siglas como CFP®, CEA®, CGA, CGE, etc"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Escritório"
            name="company"
            rules={[{ required: true, message: 'Campo obrigatório!' }]}
            help="Imagem do escritório"
          >
            <Select>
              <Option value="nenhum">Nenhum</Option>
              {ESCRITORIOS.map(v => <Option key={v.slug} value={v.slug}>{v.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            name="active"
            valuePropName="checked"
          >
            <Checkbox>Ativo</Checkbox>
          </Form.Item>

          <div className="upload-container">
            <UploadRoundImage
              fileList={profileFileList}
              setFileList={setProfileFileList}
              setImg={setProfileImg}
              img={profileImg}
              savedFileList={savedProfileFileList}
              accept=".png,.jpg,.jpeg"
              name="Stories"
            />

            <UploadRoundImage
              fileList={pdfFileList}
              setFileList={setPdfFileList}
              setImg={setPdfImg}
              img={pdfImg}
              savedFileList={savedPdfFileList}
              accept=".png,.jpg,.jpeg"
              name="Jornal"
            />

            <UploadImage
              fileList={qrCodeFileList}
              setFileList={setQrCodeFileList}
              setImg={setQrCodeImg}
              img={qrCodeImg}
              savedFileList={savedQrCodeFileList}
              accept=".jpg"
              name="QR Code"
            />
          </div>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Form.Item>

        </Form>
      </Spin>
      {/*
      <div style={{ maxWidth: 600 }}>
        <UploadProfile profileImage={profileImg?.img} fileList={profileFileList} setFileList={setProfileFileList} />
      </div>
      */}

    </div>
  );
}

export default GerenciarAssessores;
