import React, { useState, useCallback, useEffect } from 'react';
import { Button, Input, message, Modal } from 'antd';
import './ModalSaveDraftForm.scss';
import { formDraftRequests } from '../../requests';
import { FORMULARIO_ARQUIVOS, FORMULARIO_REPETIDORES, FORMULARIO_SELETORES_COR } from '../../constants/constants';

const ModalSaveDraftForm = ({ type, form, isOpen, setIsOpen, setVersion }) => {
  const [isLoading, setLoading] = useState(false);
  const [inputName, setInputName] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setInputName('');
  }, [type]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const values = form.getFieldsValue();
    const formData = {};
    const keys = Object.keys(values);

    keys.forEach((key) => {
      if (FORMULARIO_ARQUIVOS.indexOf(key) !== -1) { // Arquivos
//        if (values[key] && values[key][0]) formData.append(key, values[key][0].originFileObj);
      } else if (FORMULARIO_SELETORES_COR.indexOf(key) !== -1) { // Cores
        formData[key] = values[key].metaColor ? values[key].toRgb() : values[key];
      } else  {
        formData[key] = values[key];
      }
    });
    const formDataString = JSON.stringify(formData);

    formDraftRequests.create(inputName, type, formDataString)
      .then(response => {
        setLoading(false);
        setVersion(Math.random());
        setInputName('');
        setIsOpen(false);
        messageApi.open({
          type: 'success',
          content: 'Rascunho salvo com sucesso',
        });
      })
      .catch(error => {
        setLoading(false);
        messageApi.open({
          type: 'error',
          content: 'Ocorreu um erro ao salvar o rascunho',
        });
      });
  }, [form, setVersion, type, setIsOpen, messageApi, inputName]);

  return (
    <Modal
      open={isOpen}
      onOk={handleSubmit}
      onCancel={() => setIsOpen(false)}
      title="Salvar rascunho"
      wrapClassName="ModalSaveDraftForm"
      footer={[
        <Button key="back" onClick={() => setIsOpen(false)}>Cancelar</Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>Salvar</Button>,
      ]}
    >
      Nome (opcional): <Input value={inputName} onChange={(e) => setInputName(e.target.value)} />
      {contextHolder}
    </Modal>
  );
}

export default ModalSaveDraftForm;
