import { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';
import setCanvasPreview from './setCanvasPreview';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const ImageCropper = ({ name, profileImage, fileList, setFileList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [error, setError] = useState('');

  const onSelectFile = (file) => {
    if (!file) return;
    setIsModalOpen(true);

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || '';
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', (e) => {
        if (error) setError('');
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError('Image must be at least 150 x 150 pixels.');
          return setImgSrc('');
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    onSelectFile(profileImage);
  }, [profileImage]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const closeModal = () => setIsModalOpen(false);

  const cropImage = () => {
    setCanvasPreview(
      imgRef.current, // HTMLImageElement
      previewCanvasRef.current, // HTMLCanvasElement
      convertToPixelCrop(
        crop,
        imgRef.current.width,
        imgRef.current.height
      )
    );
    const dataUrl = previewCanvasRef.current.toDataURL();

    const f = [
      {
        uid: '-2',
        status: 'done',
        url: dataUrl,
      },
    ];
    setFileList(f);


    closeModal();
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onOk={cropImage}
        onCancel={closeModal}
        cancelText="Cancelar"
        okText="Recortar"
        title={`Recortar imagem ${name}`}
      >
        {error && <p>{error}</p>}
        {imgSrc && (
          <div>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              circularCrop
              keepSelection
              aspect={ASPECT_RATIO}
              minWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt='Upload'
                style={{ maxHeight: '70vh' }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        )}
        {crop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              display: 'none',
              border: '1px solid black',
              objectFit: 'contain',
              width: 150,
              height: 150,
            }}
          />
        )}
      </Modal>
    </div>
  );
};
export default ImageCropper;
