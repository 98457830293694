import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const getUserFromStorage = () => {
    // Tente obter o usuário a partir do localStorage
    const storedUser = localStorage.getItem('user');
    try {
      return storedUser ? JSON.parse(storedUser) : null;
    } catch {
      // Remover dados do usuário do localStorage
      localStorage.removeItem('user');
      return null;
    }
  };

  const [user, setUser] = useState(getUserFromStorage());


  const login = (token, permissions, username) => {
    const newUser = { token, permissions, username };

    // Lógica de autenticação, por exemplo, chamar uma API de login
    setUser(newUser);

    // Armazenar dados do usuário no localStorage
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const logout = () => {
    // Lógica de logout
    setUser(null);

    // Remover dados do usuário do localStorage
    localStorage.removeItem('user');
  };

  const logout401 = () => {
    // Remover dados do usuário do localStorage
    localStorage.removeItem('user');

    setUser({ ...user, token: 'logout' });
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // Retorna a resposta normalmente se não houver erros
        return response;
      },
      async (error) => {
        console.log('error ', error);
        if (error.response && error.response.status === 401) {
          logout401();
        }
        // Propaga outros erros
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, logout401 }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};