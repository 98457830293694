import React from 'react';
import { Form, Input } from 'antd';
import './FeedRiseForm.scss';

const FeedRiseForm = () => {

  return <>
    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome1"
          label="Nome alta 1"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao1"
          label="Variação alta 1"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome2"
          label="Nome alta 2"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao2"
          label="Variação alta 2"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome3"
          label="Nome alta 3"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao3"
          label="Variação alta 3"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome4"
          label="Nome alta 4"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao4"
          label="Variação alta 4"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome5"
          label="Nome alta 5"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao5"
          label="Variação alta 5"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_alta_nome6"
          label="Nome alta 6"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_alta_variacao6"
          label="Variação alta 6"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  </>
};
export default FeedRiseForm;