import React from 'react';
import { Form, Input, Select } from 'antd';
import './MinuteForm.scss';

const { Option } = Select;

const MinuteForm = () => {

  return <>
    <Form.Item
      label="Notícia 1"
      name="minute_text1"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Notícia 2"
      name="minute_text2"
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Notícia 3"
      name="minute_text3"
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Notícia 4"
      name="minute_text4"
    >
      <Input />
    </Form.Item>

    <Form.Item name="minute_cor" label="Cor" rules={[{ required: true }]}>
      <Select
//        placeholder="Select a option and change input text above"
//        allowClear
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Option value="azul">Azul</Option>
        <Option value="branco">Branco</Option>
      </Select>
    </Form.Item>


  </>
};
export default MinuteForm;