import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form, Image, Input, InputNumber, Radio, Select, Space } from 'antd';
import moment from 'moment';
import { pagesList, isDevMode } from '../../constants/constants.js';
import './ImagePreview.scss';
import { imagePreviewRequests } from '../../requests';
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

const { Option } = Select;

const ImagePreview = ({ type, validateForm }) => {
  const [form] = Form.useForm();
  const [code, setCode] = useState('');
  const [image, setImage] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelList, setModelList] = useState([]);

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  useEffect(() => {
    const item = Object.values(pagesList).find((v) => v.slug === type);
    setModelList(item ? item.models : []);
    setSelectedModel(item?.models?.length > 0 ? item.models[0].slug : null);
    setImage(null);
  }, [type]);

  const generatePreview = async (formData) => {
    try {

      const resposta = await imagePreviewRequests.imagePreview(type, selectedModel, formData);
      URL.revokeObjectURL(image);

      // Cria um URL para o Blob
      const blobUrl = URL.createObjectURL(resposta.data);

      // Atualiza o estado com o URL do Blob
      setImage(blobUrl);
    } catch (erro) {
      setImage(null);
      console.error('Erro ao realizar a requisição:', erro);
    }
  }

  const downloadImage = async () => {
    if (!type || !selectedModel || !image) return;
    try {
      const link = document.createElement('a');
      link.href = image;
      const selectedModelString = selectedModel === 'default' ? '' : `${selectedModel}-`;
      link.setAttribute('download', `${type}-${selectedModelString}${moment().format('YYYY-MM-DD_HH-mm-ss')}`); // Substitua pelo nome desejado
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  }

  return (
    <div className="ImagePreview">
      <div className="preview-container">
        <h3>Modelo:</h3>
        <div className="models-container">
          <Radio.Group value={selectedModel} onChange={(e) => { setSelectedModel(e.target.value); }} buttonStyle="solid" style={{ marginTop: 16 }}>
            {modelList.map((v) => <Radio.Button key={v.slug} value={v.slug}>{v.name}</Radio.Button>)}
          </Radio.Group>
        </div>
        <h3>Ação:</h3>
        <div className="actions-container">
          <Button onClick={() => validateForm(generatePreview)}>Visualizar</Button>
          <Button onClick={downloadImage} disabled={!image}>Baixar</Button>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="img-container" onClick={() => isDevMode ? validateForm(generatePreview) : false}>
            {image &&
              <Image
                src={image}
                alt="Pré-visualização"
                preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={50} className="toolbar-wrapper">
                      <DownloadOutlined onClick={downloadImage} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
