import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import ModalLogin from './modalLogin/ModalLogin';

const PrivateRoute = () => {
  const { user } = useAuth();

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return (user != null) ? (
    <>
      <Outlet />
      <ModalLogin isOpen={user?.token === 'logout'} />
    </>
  ) : <Navigate to="/login" />;
}

export default PrivateRoute;
