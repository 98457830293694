import { request, serverNameJava } from '../configs.js';

export const formDraftRequests = {
  getAll: (formName) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.get(`${serverNameJava}/form-drafts?formName=${formName}&v=${Math.random()}`, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'application/json',
      },
      cache: 'no-store'
    });
  },
  getById: (id) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.get(`${serverNameJava}/form-drafts/${id}`, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'application/json',
      },
      cache: 'no-store'
    });
  },
  deleteById: (id) => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.delete(`${serverNameJava}/form-drafts/${id}`, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'application/json',
      },
      cache: 'no-store'
    });
  },
  create: (name, formName, formData) => {
    const params = { name, formName, formData };
    const storedUser = JSON.parse(localStorage.getItem('user'));
    return request.post(`${serverNameJava}/form-drafts`, params, {
      headers: {
        'Authorization': `Bearer ${storedUser?.token}`,
        'Content-Type': 'application/json',
      },
    });
  },
};

export default formDraftRequests;
