import React from 'react';
import { Form, Input, Select } from 'antd';
import './FeedCurrencyForm.scss';

const { Option } = Select;

const FeedCurrencyForm = () => {

  return <>
    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Ibovespa</h3>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_currency_variacao_ibovespa"
          label="Variação"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_currency_total_ibovespa"
          label="Total"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="feed_currency_status_ibovespa" label="Variação status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>

    <h3>Dólar</h3>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_currency_variacao_dolar"
          label="Variação"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_currency_total_dolar"
          label="Total"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="feed_currency_status_dolar" label="Variação status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>

    <h3>Bitcoin</h3>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_currency_variacao_bitcoin"
          label="Variação"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_currency_total_bitcoin"
          label="Total"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="feed_currency_status_bitcoin" label="Variação status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>
  </>
};
export default FeedCurrencyForm;