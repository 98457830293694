import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Popconfirm, Space, Spin, Table } from 'antd';
import moment from 'moment';
import './FormDraftList.scss';
import { formDraftRequests } from '../../requests';


const FormDraftList = ({ type, form, version }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Criado por',
      dataIndex: 'userFullName',
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (values, record) => (
        <Space size="middle">
          <Popconfirm
            title="Os valores atuais serão sobrescritos"
            description="Deseja continuar?"
            onConfirm={() => restoreDraft(values.id)}
            onCancel={() => {}}
            okText="Sim"
            cancelText="Não"
          >
            <Button>Restaurar</Button>
          </Popconfirm>
          <Popconfirm
            title="O rascunho será excluído"
            description="Deseja continuar?"
            onConfirm={() => deleteDraft(values.id)}
            onCancel={() => {}}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger>Excluir</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const restoreDraft = async (id) => {
    try {
      const response = await formDraftRequests.getById(id);
      const formData = JSON.parse(response.data.formData);
      form.setFieldsValue(formData);

      messageApi.open({
        type: 'success',
        content: 'Valores restaurados com sucesso',
      });
    } catch {
      messageApi.open({
        type: 'error',
        content: 'Ocorreu um erro ao restaurar os valores',
      });
    }
  }

  const deleteDraft = async (id) => {
    try {
      const response = await formDraftRequests.deleteById(id);
      messageApi.open({
        type: 'success',
        content: 'Rascunho excluído com sucesso',
      });
      fetchData();
    } catch {
      messageApi.open({
        type: 'error',
        content: 'Ocorreu um erro ao excluir o rascunho',
      });
    }
  }

  const fetchData = useCallback(async () => {
    // Função para fazer a solicitação GET
    try {
      setLoading(true);
      const response = await formDraftRequests.getAll(type);
      if (!response.data.error) {
//        setData(response.data.content.map(v => ({ ...v, date: moment(v.date)})).sort((a, b) => b.date - a.date));
        setData(response.data.content);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]); // Limpa os dados caso ocorra um erro
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
  }, [messageApi, type, setLoading, version]);

  useEffect(() => {
    fetchData(); // Chama a função de busca quando o componente é montado
  }, [type, fetchData]);

  return (
    <div className="FormDraftList">
      <Spin tip="Carregando" spinning={isLoading}>
        <Table columns={columns} dataSource={data} pagination={{ hideOnSinglePage: true }} />
      </Spin>
      {contextHolder}
    </div>
  );
};

export default FormDraftList;
