import React, { useState, useCallback } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import './ModalLogin.scss';
import logo from '../../assets/images/logo-blue.png';
import { useAuth } from '../AuthContext';
import { authenticationRequests } from '../../requests';

const ModalLogin = ({ isOpen }) => {
  const { login } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [formLogin] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const username = formLogin.getFieldValue('username');
    const password = formLogin.getFieldValue('password');

    try {
      setLoading(false);
      const response = await authenticationRequests.login(username, password);
      const { data } = response;
      login(data.token, data.roles, data.username);
      messageApi.open({
              type: 'success',
              content: 'Login efetuado com sucesso',
            });
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: 'Nome de usuário ou senha inválidos',
      });
    }
  }, [formLogin, login, messageApi]);

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      wrapClassName="ModalLogin"
    >
      <Form
        form={formLogin}
        onFinish={() => handleSubmit()}
        noValidate
      >
        <div className="logo-container">
          <img className="logo-image" src={logo} alt="" />
        </div>
        <Form.Item
          name="username"
        >
          <Input className="InputField" placeholder="Login" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Por favor, insira sua senha' },
            { min: 6, message: 'A senha deve ter no mínimo 6 digitos' }
          ]}
        >
          <Input className="InputField" type="password" placeholder="Senha" />
        </Form.Item>

        <Form.Item className="login-btn">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Entrar
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
}

export default ModalLogin;
