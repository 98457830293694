import React from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './FluxoInvestimentoB3Form.scss';

const { Option } = Select;

const FluxoInvestimentoB3Form = () => {

  return <div className="FluxoInvestimentoB3Form">

    <Form.Item
      name="fluxo_investimento_b3_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="fluxo_investimento_b3_horario_brasilia"
      label="Horário"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>Itens:</h3>
    <Form.List name="fluxo_investimento_b3">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'name']}
                label="Nome"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'value']}
                label="Valor"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input />
              </Form.Item>

              <Form.Item name={[name, 'status']} label="Status" className="select" rules={[{ required: true }]} style={{ minWidth: 120}}>
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Option value="aumento">Aumento</Option>
                  <Option value="diminuicao">Diminuição</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'value1']}
                label="Valor 1"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input />
              </Form.Item>

              <Form.Item name={[name, 'month1']} label="Mês 1" className="select" rules={[{ required: true }]} style={{ minWidth: 120}}>
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Option value="JAN">Jan</Option>
                  <Option value="FEV">Fev</Option>
                  <Option value="MAR">Mar</Option>
                  <Option value="ABR">Abr</Option>
                  <Option value="MAI">Mai</Option>
                  <Option value="JUN">Jun</Option>
                  <Option value="JUL">Jul</Option>
                  <Option value="AGO">Ago</Option>
                  <Option value="SET">Set</Option>
                  <Option value="OUT">Out</Option>
                  <Option value="NOV">Nov</Option>
                  <Option value="DEZ">Dez</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'value2']}
                label="Valor 2"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input />
              </Form.Item>

              <Form.Item name={[name, 'month2']} label="Mês 2" className="select" rules={[{ required: true }]} style={{ minWidth: 120}}>
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Option value="JAN">Jan</Option>
                  <Option value="FEV">Fev</Option>
                  <Option value="MAR">Mar</Option>
                  <Option value="ABR">Abr</Option>
                  <Option value="MAI">Mai</Option>
                  <Option value="JUN">Jun</Option>
                  <Option value="JUL">Jul</Option>
                  <Option value="AGO">Ago</Option>
                  <Option value="SET">Set</Option>
                  <Option value="OUT">Out</Option>
                  <Option value="NOV">Nov</Option>
                  <Option value="DEZ">Dez</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'value3']}
                label="Valor 3"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input />
              </Form.Item>

              <Form.Item name={[name, 'month3']} label="Mês 3" className="select" rules={[{ required: true }]} style={{ minWidth: 120}}>
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                  <Option value="JAN">Jan</Option>
                  <Option value="FEV">Fev</Option>
                  <Option value="MAR">Mar</Option>
                  <Option value="ABR">Abr</Option>
                  <Option value="MAI">Mai</Option>
                  <Option value="JUN">Jun</Option>
                  <Option value="JUL">Jul</Option>
                  <Option value="AGO">Ago</Option>
                  <Option value="SET">Set</Option>
                  <Option value="OUT">Out</Option>
                  <Option value="NOV">Nov</Option>
                  <Option value="DEZ">Dez</Option>
                </Select>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default FluxoInvestimentoB3Form;