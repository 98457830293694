import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, message, Spin, Table, Tag } from 'antd';
import { EditOutlined, PlusOutlined, ClearOutlined } from '@ant-design/icons';
import './ListarAssessores.scss';
import { useNavigate } from 'react-router-dom';
import { assessoresRequests } from '../../requests';
import { ESCRITORIOS } from '../../constants/constants';

function compararBooleanos(a, b) {
  // Se 'a' for falso e 'b' for verdadeiro, 'a' deve vir antes de 'b'
  if (a === false && b === true) {
    return -1;
  }
  // Se 'a' for verdadeiro e 'b' for falso, 'b' deve vir antes de 'a'
  else if (a === true && b === false) {
    return 1;
  }
  // Caso contrário, a ordem não importa para valores booleanos
  else {
    return 0;
  }
}

function convertToDate(dateString) {
  // Verifica se a string é válida
  if (typeof dateString !== 'string' || !dateString.includes('T')) {
    throw new Error("Data inválida");
  }

  // Remove a parte dos microsegundos (mantém até milissegundos)
  const formattedDateString = dateString.slice(0, 23);

  // Cria um objeto Date
  const dateObject = new Date(formattedDateString);

  // Verifica se a data é válida
  if (isNaN(dateObject.getTime())) {
    throw new Error("Não foi possível converter para Date");
  }

  return dateObject;
}

function formatDate(date) {
  return date.toLocaleDateString('pt-BR');
}


const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Username',
    dataIndex: 'username',
    sorter: (a, b) => a.username.localeCompare(b.username),
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    sorter: (a, b) => a.username.localeCompare(b.slug),
  },
  {
    title: 'Escritório',
    dataIndex: 'company',
    sorter: (a, b) => (a.company || '').localeCompare(b.company || ''),
    render: (text) => ESCRITORIOS.find(v => v.slug === text)?.name || text,
  },
  {
    title: 'Inclusão',
    dataIndex: 'createdAt',
    render: (value) => formatDate(convertToDate(value)),
    sorter: (a, b) => new convertToDate(a.createdAt) - new convertToDate(b.createdAt),
  },
  {
    title: 'Ativo',
    dataIndex: 'active',
    sorter: (a, b) => compararBooleanos(a.active, b.active),
    render: (active) => active ? <Tag color="geekblue">Sim</Tag> : <Tag color="volcano">Não</Tag>,
  },
  {
    title: 'Ações',
    dataIndex: 'id',
    render: (id, obj) => {
      return (
        <div className="action-container">
          <a className="action-item" href={`/assessores/editar/${id}`}>
            <EditOutlined />
          </a>
        </div>
      );
    },
  },
];

function ListarAssessores() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [total, setTotal] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [inputFilter, setInputFilter] = useState('');

  const fetchData = useCallback(async () => {
    // Função para fazer a solicitação GET
    setIsLoading(true);
    try {
      const response = await assessoresRequests.getAll();
      if (!response.data.error) {
        const content = response.data;
        setData(content.map(v => ({ ...v, key: v.id})));
        setTotal(content.length);
        setTotalActive(content.reduce((amount, item) => (amount + (item.active ? 1 : 0)), 0));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setData([]); // Limpa os dados caso ocorra um erro
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
      setFilteredData('');
    }
  }, [messageApi]);

  useEffect(() => {
    fetchData(); // Chama a função de busca quando o componente é montado
  }, [fetchData]);

  useEffect(() => {
    if (inputFilter) {
      setFilteredData(data.filter(item => item.name.toLowerCase().includes(inputFilter.toLowerCase())));
    } else {
      setFilteredData(data);
    }
  }, [inputFilter, data]);

  async function cleanData() {
    setInputFilter('');
    setFilteredData(data);
  }

  return (
    <div className="ListarAssessores">
      {contextHolder}
      <Spin tip="Carregando" spinning={isLoading}>
        <div className="header">
          <div className="header-total">
            {!!total && <div className="total-item"><b>Total:</b> {total}</div>}
            {!!totalActive && <div className="total-item"><b>Total ativo:</b> {totalActive}</div>}
          </div>
          <div className="add-new-container">
            <Button type="primary" onClick={() => navigate('/assessores/novo')}><PlusOutlined /> Adicionar novo</Button>
          </div>
        </div>





        <div className="filter-form-container">
          <div className="filter-title">
            Filtrar assessores
          </div>
          <div className="filter-form">
            <Input placeholder="Filtrar por nome" value={inputFilter} onChange={(e) => setInputFilter(e.target.value)} />
            <Button className="button-filter" onClick={cleanData}><ClearOutlined />Limpar</Button>
          </div>
        </div>




        <Table columns={columns} dataSource={filteredData} pagination={{ position: ['topRight'] }} />
      </Spin>
    </div>
  )
}

export default ListarAssessores;
