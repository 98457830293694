import React from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './TesouroDiretoForm.scss';

const { Option } = Select;

const TesouroDiretoForm = () => {

  return <div className="TesouroDiretoForm">

    <div className="line-full">
      <Form.Item
        name="tesouro_direto_model"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
        style={{ minWidth: 260 }}
        label="Modelo"
      >
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="stories">Stories</Option>
          <Option value="feed">Feed</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="tesouro_direto_data"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="tesouro_direto_horario_brasilia"
        label="Horário"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h3>Itens:</h3>
    <Form.List name="tesouro_direto">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'value']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Valor" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'name']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Nome" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'year']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Ano" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'date']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Data" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'color']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Cor">
                  <Option value="blue">Azul</Option>
                  <Option value="red">Vermelho</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'juros_semestrais']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
                valuePropName="checked"
              >
                <Checkbox>Juros semestrais?</Checkbox>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default TesouroDiretoForm;